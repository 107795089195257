<template>
	<el-dialog title="" :visible.sync="createdVisible" width="578px" center :close-on-click-modal="false" @close="onResetForm('ruleForm')">
		<div slot="title" class="postion">
			<i v-if="!isShowDetail" class="iconfont icon-bianji1"></i>
			<span class="download">{{ isShowDetail ? '查看' : !isShowDetail && ruleForm.id ? '编辑' : '新增' }}名师</span>
		</div>
		<el-form :model="ruleForm" :rules="isShowDetail ? {} : rules" ref="ruleForm" label-width="100px" class="demo-ruleForm" label-position="top">
			<el-form-item label="姓名" prop="name">
				<el-input v-model.trim="ruleForm.name" :disabled="isShowDetail" placeholder="请输入"></el-input>
			</el-form-item>
			<el-form-item label="专业" prop="major">
				<el-input v-model.trim="ruleForm.major" :disabled="isShowDetail" placeholder="请输入"></el-input>
			</el-form-item>
			<el-form-item label="头衔" prop="titleList" class="tagsClass">
				<el-tag
					:key="tag"
					v-for="tag in ruleForm.titleList"
					:closable="!isShowDetail"
					:disable-transitions="false"
					@close="handleClose(tag, 'titleList')"
				>
					{{ tag }}
				</el-tag>
				<el-input
					class="input-new-tag"
					v-if="titleInputVisible"
					v-model="titleInput"
					ref="saveTagInputTitle"
					size="small"
					@keyup.enter.native="handleInputConfirm('titleInput', 'titleInputVisible', 'titleList')"
					@blur="handleInputConfirm('titleInput', 'titleInputVisible', 'titleList')"
				>
				</el-input>
				<el-button
					v-if="!titleInputVisible && ruleForm.titleList.length < 5 && !isShowDetail"
					class="button-new-tag"
					size="small"
					@click="showInput('titleInputVisible', 'saveTagInputTitle')"
					>+ 添加标签</el-button
				>
			</el-form-item>
			<el-form-item label="擅长" prop="goodNameList" class="tagsClass">
				<el-tag
					:key="tag"
					v-for="tag in ruleForm.goodNameList"
					:closable="!isShowDetail"
					:disable-transitions="false"
					@close="handleClose(tag, 'goodNameList')"
				>
					{{ tag }}
				</el-tag>
				<el-input
					class="input-new-tag"
					v-if="goodInputVisible"
					v-model="goodInput"
					ref="saveTagInput"
					size="small"
					@keyup.enter.native="handleInputConfirm('goodInput', 'goodInputVisible', 'goodNameList')"
					@blur="handleInputConfirm('goodInput', 'goodInputVisible', 'goodNameList')"
				>
				</el-input>
				<el-button
					v-if="!goodInputVisible && ruleForm.goodNameList.length < 4 && !isShowDetail"
					class="button-new-tag"
					size="small"
					@click="showInput('goodInputVisible', 'saveTagInput')"
					>+ 添加标签</el-button
				>
			</el-form-item>
			<el-form-item label="名师成果" prop="description">
				<el-input v-model.trim="ruleForm.description" :disabled="isShowDetail" placeholder="请输入" type="textarea" autosize></el-input>
			</el-form-item>
			<el-form-item label="名师照片" prop="images">
				<Upload
					ref="uploadImage"
					v-model="ruleForm.images"
					modulePath="FAMOUS_TEACHER"
					@uploadImg="uploadImg"
					operator="zhangl"
					:maxCount="1"
					:disabled="isShowDetail"
				></Upload>
			</el-form-item>
		</el-form>
		<span slot="footer" class="dialog-footer">
			<el-button @click="onResetForm('ruleForm')" type="primary" v-if="isShowDetail">确 定</el-button>
			<el-button v-else type="primary" @click="onSave('ruleForm')" :loading="saveLoading">保 存</el-button>
			<el-button v-if="!isShowDetail" @click="onResetForm('ruleForm')">取 消</el-button>
		</span>
	</el-dialog>
</template>

<script>
export default {
	components: {
		Upload: () => import('@/components/Upload/index.vue'),
	},
	data() {
		return {
			createdVisible: false,
			ruleForm: {
				name: '',
				major: '',
				titleList: [],
				goodNameList: [],
				images: [],
				description: '',
			},
			rules: {
				name: [{ required: true, message: '请输入', trigger: 'blur' }],
				major: [{ required: true, message: '请输入', trigger: 'blur' }],
				titleList: [{ required: true, message: '请输入', trigger: 'blur' }],
				goodNameList: [{ required: true, message: '请输入', trigger: 'blur' }],
				description: [{ required: true, message: '请输入', trigger: 'blur' }],
				images: [{ required: true, message: '请上传', trigger: 'blur' }],
			},
			attachmentInfoPhoto: {},
			saveLoading: false,
			titleInputVisible: false,
			titleInput: '',
			goodInputVisible: false,
			goodInput: '',
		};
	},
	props: ['isShowDetail'],
	watch: {},
	created() {},
	methods: {
		init(data) {
			this.createdVisible = true;
			this.getAttachmentGroups();
			if (data.row) {
				this.getDetails(data.row);
				this.$nextTick(() => {
					this.$refs.ruleForm.clearValidate();
				});
				return;
			}
			// 新增
			this.ruleForm = this.$options.data().ruleForm; //清空表单
			this.$nextTick(() => {
				this.$refs.ruleForm.clearValidate();
			});
		},
		getDetails(row) {
			this.$http
				.get(this.api.getFamousTeachers + `/${row.id}.json`)
				.then((res) => {
					if (res.data && res.data.success) {
						this.setRuleForm(res.data.collection[0]);
					}
				})
				.catch((e) => {});
		},
		handleClose(tag, list) {
			this.ruleForm[list].splice(this.ruleForm[list].indexOf(tag), 1);
			this.$forceUpdate();
		},
		showInput(visible, refName) {
			this[visible] = true;
			this.$nextTick((_) => {
				this.$refs[refName].$refs.input.focus();
			});
		},
		handleInputConfirm(type, visible, list) {
			let inputValue = this[type];
			if (inputValue) {
				this.ruleForm[list].push(inputValue);
			}
			this[visible] = false;
			this[type] = '';
		},
		// 修改/查看
		setRuleForm(data) {
			this.ruleForm = JSON.parse(JSON.stringify(data));
			this.ruleForm.titleList = data.honor?.split(',') || [];
			this.ruleForm.goodNameList = data.forte?.split(',') || [];
			this.ruleForm.images = data.attachmentMap['PHOTO']?.map((item) => {
				return {
					title: item.title,
					url: item.link,
					id: item.id,
				};
			});
		},
		// 获取图片附件组
		getAttachmentGroups() {
			this.$http
				.get(this.api.getAttachmentGroups, { params: { subjectTypeCode: 'FAMOUS_TEACHER' } })
				.then((res) => {
					if (res.data && res.data.success) {
						this.attachmentInfoPhoto = res.data.collection || [];
					}
				})
				.catch((e) => {});
		},
		onResetForm() {
			this.createdVisible = false;
			this.ruleForm.images = [];
			this.$nextTick(() => {
				this.$refs.ruleForm.resetFields();
			});
		},
		//保存
		onSave(formName) {
			this.$refs[formName].validate((valid) => {
				if (valid) {
					this.saveLoading = true;
					const { images } = this.ruleForm;
					let params = {
						...this.ruleForm,
						honor: this.ruleForm.titleList.join(','),
						forte: this.ruleForm.goodNameList.join(','),
						attachments: images.map((item) => {
							return {
								link: item.url,
								subjectTypeCode: this.attachmentInfoPhoto.find((item) => item.code == 'PHOTO').subjectTypeCode,
								title: item.title,
								attachmentGroupId: this.attachmentInfoPhoto.find((item) => item.code == 'PHOTO').id,
								mimeType: item.title.split('.')[1],
							};
						}),
					};
					let queryApi = this.ruleForm.id
						? this.$http.put(this.api.getFamousTeachers + `/${this.ruleForm.id}.json`, params)
						: this.$http.post(this.api.getFamousTeachers + `.json`, params);
					queryApi
						.then((res) => {
							if (res.data && res.data.success) {
								this.$message.success(this.ruleForm.id ? '修改成功' : '添加成功');
								this.$emit('getList');
								this.saveLoading = false;
								this.onResetForm('ruleForm');
							}
						})
						.catch((e) => {
							this.saveLoading = false;
						});
				}
			});
		},
		uploadImg(dataImg) {
			this.ruleForm.images = dataImg.map((item) => {
				return {
					url: item.url,
					uid: item.uid,
					title: item.title,
				};
			});
		},
	},
};
</script>

<style lang="scss" scoped>
.postion {
	font-size: 18px;
	.icon-bianji1 {
		margin-right: 16px;
		color: #1db9b1;
	}
}
/deep/ .el-form {
	.el-form-item {
		.el-select,
		.el-input {
			width: 100%;
		}
	}
	.el-form-item__label {
		line-height: 14px;
		height: 14px;
		margin-bottom: 8px;
		padding: 0;
	}
	.tagsClass {
		.el-tag {
			margin-right: 16px;
		}
		.el-input {
			width: 88px;
		}
		.el-button--default {
			margin-left: 0;
			padding: 9px 16px;
		}
	}
}
/deep/ .el-upload-list--picture-card .el-upload-list__item {
	width: 100px;
	height: 100px;
}
/deep/ .el-upload {
	.el-button--primary {
		border-color: #c4c4c4;
		color: #333;
		background-color: #fff;
		margin-left: 0;
	}
	.is-disabled {
		background-color: #f4f3f3 !important;
		border-color: #e4e7ed !important;
		color: #c0c4cc !important;
	}
}
/deep/ .el-upload--picture-card {
	width: 100px;
	height: 100px;
	line-height: 100px;
}
/deep/ .el-button {
	margin-left: 16px;
}
.hide {
	// display: none;
	/deep/ .el-upload-list {
		.el-icon-close,
		.el-icon-close-tip {
			display: none;
		}
	}
}
/deep/ .el-textarea__inner {
	min-height: 64px !important;
	line-height: 18px !important;
	padding: 5px 12px !important;
}
</style>